<template>
    <div class="wrapper-sidebar">
        <div class="widget woof_Widget sidebar-filters_wrap">
            <div
                v-if="searchQuery"
                class="category-content-wrapper"
            >
                <div
                    v-for="category in categories"
                    :key="category.id"
                    class="category-wrapper"
                >
                    <ul v-if="!category.parent_id">
                        <li class="category-content">
                           <div class="first-section">
                               <span class="parent-category">{{ categoryName(category.name) }}</span>
                               <div
                                   v-if="category.sub_sections.length"
                                   @click="toggleCategory(category.id)"
                               >
                                   <div class="show-subCats" v-if="isCategoryOpen(category.id)">
                                       <i class="fa fa-minus" aria-hidden="true"></i>
                                   </div>
                                   <div v-else>
                                       <i class="fa fa-plus" aria-hidden="true"></i>
                                   </div>
                               </div>
                           </div>
                            <transition>
                                <div
                                    v-show="isCategoryOpen(category.id)"
                                    v-if="category.sub_sections.length"
                                >
                                    <SubSections
                                        :selectedSubCategories="selectedSearchedSubCategories"
                                        :sections="category.sub_sections"
                                        @updated="updateSelectedBoxes"
                                    />
                                </div>
                            </transition>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div class="widget widget-categories sidebar-filter" v-if="categories.length">
                    <h3 class="widgettitle">{{ $t('selectType') }}</h3>
                    <ul class="list-categories scroll-content">
                        <li v-for="cat in categories" :key="cat.id">
                            <Checkbox
                                id="category"
                                :label="categoryName(cat.name)"
                                :input-value="cat.id"
                                v-model="selectedCategories"
                                @change.native="getCategoryBrandsAndSizes"
                                class="checkbox-color"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="widget widget_filter_price sidebar-filter" v-if="price">
                <h4 class="widgettitle">
                    {{ $t('selectPrice') }}
                </h4>
                <div class="price-slider-wrapper">
                    <div class="price-input">
                        <input
                            v-model="filters.minPrice"
                            id="min" :placeholder="$t('min_price')"
                            @change="changeFilter('minPrice')"
                        />
                    </div>
                    <div class="line">-</div>
                    <div class="price-input">
                        <input
                            v-model="filters.maxPrice"
                            id="max"
                            :placeholder="$t('max_price')"
                            @change="changeFilter('maxPrice')"
                        />
                    </div>
                </div>
            </div>
            <div class="widget widget-brand sidebar-filter" v-if="brands.length">
                <h3 class="widgettitle">{{ $t('selectBrand') }}</h3>
                <ul class="list-brand scroll-content">
                    <li v-for="brand in brands" :key="brand.id">
                        <Checkbox
                            id="brand"
                            :label="brand.name"
                            :input-value="brand.id"
                            v-model="filters.selectedBrands"
                            @change.native="changeFilter('brands')"
                        />
                    </li>
                </ul>
            </div>
            <div class="widget widget_filter_size sidebar-filter" v-if="sizes.length">
                <h4 class="widgettitle">{{ $t('selectSize') }}</h4>
                <ul class="list-brand scroll-content">
                    <li v-for="size in sizes" :key="size.id">
                        <Checkbox
                            id="size"
                            :label="size.name"
                            :input-value="size.id"
                            v-model="filters.selectedSizes"
                            @change.native="changeFilter('sizes')"
                        />
                    </li>
                </ul>
            </div>
            <div class="widget widget-color sidebar-filter" v-if="colors">
                <h4 class="widgettitle">
                    {{ $t('selectColor') }}
                </h4>
                <div class="list-color scroll-content">
                    <a v-for="color in colors"
                       :key="color.id"
                       :style="{backgroundColor: color.code}"
                       :class="{
                           active: filters.selectedColors.includes(color.id),
                           'black-check': color.name === 'White'
                         }"
                       class="pointer"
                       @click="selectColor(color)"
                    ></a>
                </div>
            </div>
            <div class="widget widget-tags sidebar-filter" v-if="tags && tags.length">
                <h3 class="widgettitle">
                    {{ $t('mostViewed') }}
                </h3>
                <ul class="tagcloud scroll-content">
                    <li
                        class="tag-cloud-link pointer"
                        v-for="tag in tags"
                        :key="tag.id"
                        :class="{active: filters.selectedTags.includes(tag.id) }"
                        @click="selectTag(tag)"
                    >
                        <a class="pointer">{{ tag.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from "@/components/custom-input/Checkbox";
import {mapGetters, mapMutations} from "vuex";
import SubSections from "@/components/SubSections.vue";

export default {
    name: "Sidebar",
    components: {SubSections, Checkbox},
    props: {
        categories: {
            type: Array,
            default: () => null
        },
        brands: {
            type: Array,
            default: () => null
        },
        colors: {
            type: Array,
            default: () => null
        },
        sizes: {
            type: Array,
            default: () => null
        },
        tags: {
            type: Array,
            default: () => null
        },
        price: {
            type: Object,
            default: () => null
        },
        per_page: {
            default: () => null
        },
        by_price: {
            default: () => null
        },
        select_page: {
            default: () => null
        },
        selectedSearchedSubCategories: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            filters: {
                selectedCategories: [],
                selectedBrands: [],
                selectedColors: [],
                selectedSizes: [],
                selectedTags: [],
                minPrice: "",
                maxPrice: "",
            },
            selectedCategories: [],
            selectedSubCategories: [],
            selectedPage: null,
            showCategories: null,
            selectedSearchCategories: [],
            openCategoryIds: [],
        }
    },
    computed: {
        ...mapGetters(["settings", "page", "category", "otherFilters", "categoryBrands", 'categorySizes']),

        searchQuery() {
            return this.$route.query.search || '';
        }
    },
    watch: {
        selectedSearchedSubCategories: {
            handler(val) {
                this.selectedSearchCategories = val
            }
        },
        select_page: {
            deep: true,
            handler(val) {
                this.selectedPage = val
                if (this.searchQuery) {
                    this.filterSearchProduct()
                } else {
                    this.filterProduct()
                }
            }
        },
        categories: {
            deep: true,
            handler(val) {
                this.checkSelectedCategories(this.$route.query['filter[categories]'])

                val.map(item => {
                    this.toggleCategory(item.id)
                })
            }
        },
        per_page: {
            deep: true,
            handler() {
                this.selectedPage = 1
                if (this.searchQuery) {
                    this.filterSearchProduct()
                } else {
                    this.filterProduct()
                }
            }
        },
        by_price: {
            deep: true,
            handler() {
                this.selectedPage = 1
                if (this.searchQuery) {
                    this.filterSearchProduct()
                } else {
                    this.filterProduct()
                }
            }
        },
        '$route.query': {
            immediate: true,
            deep: true,
            async handler(val) {
                if (val['filter[categories]']) {
                    this.filters.selectedCategories = this.queryToArray(val['filter[categories]']);
                    this.checkSelectedCategories(val['filter[categories]'])
                }
                if (val['filter[brands]']) {
                    this.filters.selectedBrands = this.queryToArray(val['filter[brands]'])
                }
                if (val['filter[colors]']) {
                    this.filters.selectedColors = this.queryToArray(val['filter[colors]'])
                }
                if (val['filter[sizes]']) {
                    this.filters.selectedSizes = this.queryToArray(val['filter[sizes]'])
                }
                if (val['filter[tags]']) {
                    this.filters.selectedTags = this.queryToArray(val['filter[tags]'])
                }
                if (val['filter[by_price]']) {
                    this.setByPrice(val['filter[by_price]'])
                }
                if (val['filter[per_page]']) {
                    this.setPerPage(val['filter[per_page]'])
                }
                if (val['filter[page]']) {
                    this.setPage(val['filter[page]'])
                }
                if (val['filter[min_price]']) {
                    this.filters.minPrice = this.queryToArray(val['filter[min_price]'])
                }
                if (val['filter[max_price]']) {
                    this.filters.maxPrice = this.queryToArray(val['filter[max_price]'])
                }
            }
        },

    },
    mounted() {
        if (this.searchQuery) {
            this.filterSearchProduct('search')
        } else {
            this.filters.selectedCategories = this.queryToArray(this.$route.query['filter[categories]'])
            this.filterProduct()
        }
    },
    methods: {
        ...mapMutations(["setByPrice", "setPage", "setPerPage", 'setCategoryBrands', 'setCategorySizes']),

        checkSelectedCategories(categoryId) {
            if (this.categories.length) {
                let categoryFound = this.categories.some(item => item.id == categoryId);

                if (categoryFound) {
                    if (Array.isArray(categoryId)) {
                        this.selectedCategories = categoryId;
                    } else {
                        this.selectedCategories.push(categoryId);
                    }
                }
            }
        },

        getCategoryBrandsAndSizes() {
            this.selectedPage = 1
            if (!this.selectedCategories.length) {
                this.filters.selectedCategories = []
                this.filters.selectedCategories.push(this.categories[0].parent_id)
                this.selectedPage = null
            }
            this.$store.dispatch('getCategoryBrands', this.selectedCategories.length ? this.selectedCategories : this.filters.selectedCategories)
            this.$store.dispatch('getCategorySizes', this.selectedCategories.length ? this.selectedCategories : this.filters.selectedCategories)

            if (this.searchQuery) {
                this.filterSearchProduct()
            } else {
                this.filterProduct()
            }
        },

        changeFilter(filterType) {
            this.selectedPage = 1;
            if (
                (filterType === 'minPrice' && !this.filters.minPrice) ||
                (filterType === 'maxPrice' && !this.filters.maxPrice) ||
                (filterType === 'brands' && !this.filters.selectedBrands.length) ||
                (filterType === 'sizes' && !this.filters.selectedSizes.length)
            ) {
                this.selectedPage = null;
            }
            if (this.searchQuery) {
                this.filterSearchProduct()
            } else {
                this.filterProduct()
            }
        },

        queryToArray(val) {
            return typeof val === 'string' ? val.split(",").map(item => parseInt(item)) : val
        },

        selectColor(color) {
            this.selectedPage = 1
            if (!this.filters.selectedColors.length) {
                this.selectedPage = null
            }
            if (this.filters.selectedColors.includes(color.id)) {
                this.filters.selectedColors = this.filters.selectedColors.filter(item => item !== color.id)
            } else {
                this.filters.selectedColors.push(color.id)
            }
            if (this.searchQuery) {
                this.filterSearchProduct()
            } else {
                this.filterProduct()
            }
        },

        selectTag(tag) {
            this.selectedPage = 1
            if (!this.filters.selectedTags.length) {
                this.selectedPage = null
            }
            if (this.filters.selectedTags.includes(tag.id)) {
                this.filters.selectedTags = this.filters.selectedTags.filter(item => item !== tag.id)
            } else {
                this.filters.selectedTags.push(tag.id)
            }
            if (this.searchQuery) {
                this.filterSearchProduct()
            } else {
                this.filterProduct()
            }
        },

        filterSearchProduct(type) {
            const data = {
                'filter[categories]': this.selectedSearchCategories,
                'filter[brands]': this.filters.selectedBrands,
                'filter[colors]': this.filters.selectedColors,
                'filter[sizes]': this.filters.selectedSizes,
                'filter[tags]': this.filters.selectedTags,
                'filter[by_price]': this.by_price,
                'filter[per_page]': this.per_page,
                'filter[min_price]': this.filters.minPrice,
                'filter[max_price]': this.filters.maxPrice,
                search: this.$route.query.search,
                'filter[page]': this.selectedPage ? this.selectedPage : this.page,
            }
            if (type) {
                data['filter[searchType]'] = type;
            }

            const queryData = {...data}
            const params = new URLSearchParams(queryData).toString();
            window.history.replaceState(null, null, '?' + params);
            this.$store.dispatch('getFilteredProducts', data)
        },

        filterProduct() {
            const data = {
                'filter[categories]': this.selectedCategories.length ? this.selectedCategories : this.filters.selectedCategories,
                'filter[brands]': this.filters.selectedBrands,
                'filter[colors]': this.filters.selectedColors,
                'filter[sizes]': this.filters.selectedSizes,
                'filter[tags]': this.filters.selectedTags,
                'filter[by_price]': this.by_price,
                'filter[per_page]': this.per_page,
                'filter[min_price]': this.filters.minPrice,
                'filter[max_price]': this.filters.maxPrice,
                'filter[page]': this.selectedPage ? this.selectedPage : this.page,
            }
            const queryData = {...data}
            const params = new URLSearchParams(queryData).toString();
            window.history.replaceState(null, null, '?' + params);
            this.$store.dispatch('getFilteredProducts', data)
        },

        categoryName(names) {
            return names[this.$i18n.locale]
        },

        updateSelectedBoxes(value) {
            if(!this.selectedSearchCategories.includes(value)){
                this.selectedSearchCategories.push(value)
            }else{
                const index = this.selectedSearchCategories.indexOf(value);
                this.selectedSearchCategories.splice(index,1)
            }
            this.filterSearchProduct()
        },

        toggleCategory(id) {
            const index = this.openCategoryIds.indexOf(id);
            if (index > -1) {
                this.openCategoryIds.splice(index, 1);
            } else {
                this.openCategoryIds.push(id);
            }
        },
        isCategoryOpen(id) {
            return this.openCategoryIds.includes(id);
        },
    }
}
</script>

<style lang="scss">

.scroll-content::-webkit-scrollbar-track
{
    border-radius: 10px;
    background-color: #ffffff;
}

.scroll-content::-webkit-scrollbar
{
    width: 7px;
    background-color: #ffffff;
}

.scroll-content::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #7F7F7F;
}

.widget_filter_price {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.price-slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .line {
        margin: 0 8px;
    }

    .price-input {
        width: 50%;

        label {
            margin: 0;
            color: #555;
        }

        input {
            width: 100%;
            color: #555;
            border: 1px solid #c9c9c9;
            border-radius: 10px;
            padding: 5px;
        }

        input::placeholder {
            color: #c9c9c9;
        }
    }
}
.category-content-wrapper {
    width: 100%;
    border: 1px solid #F1F1F1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    .category-wrapper {
        margin-bottom: 10px;
        padding-bottom: 10px;
        .category-content {
            .first-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .parent-category {
                    font-weight: bold;
                }
            }

        }
    }
    .children {
        margin-bottom: 10px;
        border-bottom: 3px solid #F1F1F1;
        padding-bottom: 10px;
        margin-top: 10px;
    }
}
.sidebar {
    padding: 0 5px;
}
.sidebar-filters_wrap {
    > div {
        width: 100%;
        margin-bottom: 20px;
    }
}

.list-categories {
    max-height: 170px;
    min-height: auto;
    overflow-y: auto;
}

.list-brand {
    max-height: 170px;
    min-height: auto;
    overflow-y: auto;
}

.list-color {
    max-height: 170px;
    min-height: auto;
    overflow-y: auto;
}

.tagcloud {
    max-height: 170px;
    min-height: auto;
    overflow-y: auto;
}

</style>